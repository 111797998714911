import { navigate } from "gatsby-link";
import * as React from "react";
import ReactReadMoreReadLess from "react-read-more-read-less";
import "./ServiceCard.scss";
import Img from "gatsby-image";
import { Link } from "gatsby";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import { getVideoId } from "../../utils";
import DummyPic from "../../../images/dummy-profile-pic.jpg";
import YouTube from "react-youtube";
import Vimeo from '@u-wave/react-vimeo';
import moment from "moment";
import classNames from "classnames";

const ImageRenderList = (img) => {
    
    if(img?.cardImg){
        return (
            <ImageTransform 
                imagesources={img?.cardImg}
                renderer="srcSet" 
                imagename={img.imagename}
                attr={{ alt: img?.imgAlt, className: img?.class }}
                imagetransformresult={img.processedImages}
                id={img?.id}
            />                           
        )
    }else{
        return (
            <img src={DummyPic} alt={img?.imgAlt} 
            />                           
        )
    }
   
}

const ServiceCard = (props) => {
    const [viewmore, setViewmore] = React.useState(false);
    const [isPlaying, setPlaying] = React.useState(false);
    
    const viewMoreHandler = () => {
        setViewmore(true);
    };
    const navigateToUrl = () => {
        if(props.navigateLink && !isPlaying) {
            navigate(props.navigateLink);
        }
    } 
    return (
        <div className="service-card">
            <figure className={classNames("img-zoom position-relative", {"video-inline-h": (isPlaying && props.playInline) })}>
                {props.imagetransforms == true ? 
                    <React.Fragment>
                        {props.navigateLink ? 
                            <Link to={props.navigateLink} disabled className="w-100 h-100">
                                <ImageRenderList
                                    {...props}
                                />
                            </Link> :
                            <ImageRenderList
                                {...props}
                            />
                        }
                    </React.Fragment> :
                    <React.Fragment>
                        {props.fluidImg ? 
                            props.navigateLink ?
                                <Link to={props.navigateLink} className="w-100 h-100">
                                    <Img fluid={props?.fluidImg?.childImageSharp?.fluid} alt={props.imgAlt} className="h-100"/> 
                                </Link> :
                                <Img fluid={props?.fluidImg?.childImageSharp?.fluid} alt={props.imgAlt} className="h-100"/> 
                            :
                            props.navigateLink ?
                            <Link to={props.navigateLink} className="w-100 h-100">
                                <img src={props.cardImg} alt={props.imgAlt}/>
                            </Link> :
                            <img src={props.cardImg} alt={props.imgAlt} onClick={navigateToUrl}/>
                        }
                    </React.Fragment>
                }
                {(props.video && !isPlaying) && 
                    <strong
                        className="play-btn"
                        onClick={(e) => {
                            if(props.playInline) {
                                setPlaying(true);
                            } else {
                                props.playVideo()
                            }
                        }}
                    >
                        <i className="icon-play"></i>
                    </strong>
                }
                {(props.video && isPlaying) && 
                    <strong
                        className="play-btn play-btn-close"
                        onClick={(e) => {
                            setPlaying(false);
                        }}
                    >
                        <i className="icon-close"></i>
                    </strong>
                }
                {(props.video && props.playInline) && <div className="inline-video inline-video-container">
                    {isPlaying && 
                        <React.Fragment>
                            {props.vimeo ? <Vimeo
                                video={props.video}
                                autoplay
                                className="inline-video"
                                showPortrait
                                showByline={false}
                                loop={true}
                                responsive
                                controls={false}
                            /> :
                            <YouTube
                                className="inline-video"
                                containerClassName="sa"
                                videoId={getVideoId(props?.video)}
                                opts={{
                                    height: "100%",
                                    width: "100%",
                                    playerVars: {
                                        autoplay: 1,
                                        disablekb: 1,
                                        showinfo: 0,
                                        controls: 1,
                                        title: 0,
                                        loop: 1,
                                        modestbranding: 0
                                    }
                                }}
                            />}
                        </React.Fragment>
                    }
                </div>}
            </figure>
            <div className="info">
                {props.navigateLink ? <Link className="d-block" to={props.navigateLink}><h6 className="card-title w-100">{props.cardTitle}</h6></Link> : <h6 className="card-title w-100">{props.cardTitle}</h6>}
                {(props.cardDesc && !props.searchLink && !props.videoLink) && <p className="card-desc">{props.cardDesc}</p>}
                {props.videoLink && 
                    <Link to={props.navigateLink}>
                        <p className="card-desc">{props.cardDesc}</p>
                    </Link>
                }
                {props.searchLink && 
                    <p className="card-desc">
                        <ReactReadMoreReadLess
                            charLimit={props.calculator ? 100 : 170}
                            readMoreText={"View more"}
                            readLessText={"View less"}
                            readMoreClassName="team-link text-uppercase active"
                            readLessClassName="team-link text-uppercase"
                        >
                            {props.cardDesc}
                        </ReactReadMoreReadLess>
                    </p>
                }
                {props.date && 
                    <p>{moment(props.date).format("DD/MM/YYYY")}</p>
                }
                {props.designation && <p className="team-link text-uppercase active">{props.designation}</p>}
                {(props.agencyNavLink && <Link to={props.agencyNavLink}><p className="team-link text-uppercase active">{props.agencyNavLabel}</p></Link>) }
            </div>
        </div>
    );
};

export default ServiceCard;
